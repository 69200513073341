import { Text, Flex, Link, OrderedList, ListItem } from '@chakra-ui/react'

interface Props {
  fullname: string
  logo: React.ReactNode
  projectLink?: string
}

export const Success = ({ fullname, logo, projectLink }: Props) => {
  return (
    <Flex direction="column" textAlign="center" align="center">
      {logo}

      <Text mb={8}>Merci de votre participation et d’apporter votre soutien à {fullname} !</Text>

      <Text fontWeight="bold" mb={8}>
        Pour finaliser votre vote, merci de confirmer votre vote :
        <OrderedList my={4} stylePosition="inside">
          <ListItem>Vous avez reçu un e-mail de confirmation à l’adresse indiquée</ListItem>
          <ListItem>Cliquez sur le lien dans l’e-mail</ListItem>
          <ListItem>Votre vote est pris en compte</ListItem>
        </OrderedList>
      </Text>

      <Text mb={24}> Merci encore ! </Text>

      <>
        {projectLink && (
          <Link fontStyle="italic" fontSize="14" href={projectLink + '&utm_content=confirmationvote'}>
            Découvrez le détail du projet de {fullname}.
          </Link>
        )}
      </>
    </Flex>
  )
}
