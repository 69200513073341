import { Modal, ModalOverlay, ModalContent, ModalBody, Button } from '@chakra-ui/react'
import { useState } from 'react'
import { Close } from '@miimosa/design-system'
import { Contact, VoteFormData } from '@miitypes'

import { Success } from './Success'
import { Vote } from './Vote'

interface Props {
  isOpen: boolean
  slug: string
  projectLink?: string
  fullname: string
  contact?: Contact
  onVoted: (slug: string, data: VoteFormData) => void
  onClose: () => void
  text: string
  platform: string
  logo: React.ReactNode
  borderRadius: string
}

export const Form = ({
  isOpen,
  contact,
  slug,
  projectLink,
  onClose,
  onVoted,
  fullname,
  text,
  platform,
  logo,
  borderRadius,
}: Props) => {
  const [share, setShare] = useState(false)

  const onSuccess = (data: VoteFormData) => {
    setShare(true)
    onVoted(slug, data)
  }
  return (
    <Modal isOpen={isOpen} size={'xl'} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />
      <ModalContent
        minWidth={{ md: '1000px' }}
        maxHeight="90%"
        overflow="scroll"
        border="5px solid"
        borderColor="brand"
        borderRadius={borderRadius}
        sx={{
          '::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for Webkit browsers
          },
          msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
          scrollbarWidth: 'none', // Hide scrollbar for Firefox
        }}
      >
        <ModalBody py={4} px={{ base: 6, md: 12 }} position="relative">
          <Button onClick={onClose} position="absolute" right={7}>
            <Close w="20px" h="20px" />
          </Button>

          {share ? (
            <Success fullname={fullname} logo={logo} projectLink={projectLink} />
          ) : (
            <Vote
              slug={slug}
              onSuccess={onSuccess}
              contact={contact}
              fullname={fullname}
              text={text}
              platform={platform}
              logo={logo}
              borderRadius={borderRadius}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default Form
